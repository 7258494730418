import React from "react";
import { ArticleBar } from "../../components/faq";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { StyledPrimaryHeader } from "../../components/styled";

const Venue = () => {
  return (
    <Layout className="page__venue">
      <ArticleBar className="page-container">
        <div className="venue-container">
          <div className="venue-content-container">
            <StyledPrimaryHeader>Venue</StyledPrimaryHeader>
            <div>
              <h2>RITZ-CARLTON</h2>
              <span>1250 S Hayes St, Arlington, VA 22202</span>
              <h3>Getting There</h3>
              <div>
                <h4>Parking</h4>
                <p>
                  Valet Parking is available at the Ritz-Carlton, Pentagon City.
                  The entrance to the Ritz-Carlton, Pentagon CitValet is 1250 S
                  Hayes St, Arlington, VA 22202. There is a flat rate of $19 for
                  event attendees
                </p>
              </div>
              <div>
                <h4>Metro</h4>
                <p>
                  A metro stop is within walking distance. Pentagon City Metro |
                  Blue and Yellow lines
                </p>
              </div>
            </div>
          </div>
          <div className="maps-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3106.684287531561!2d-77.06274034889542!3d38.86258325650005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7b6d92c663a6b%3A0x8f7358a7dab14bcb!2sThe%20Ritz-Carlton%2C%20Pentagon%20City!5e0!3m2!1sen!2sus!4v1655342307518!5m2!1sen!2sus"
              width="501px"
              height="525px"
              style={{ border: 0 }}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </ArticleBar>
    </Layout>
  );
};

export default Venue;

export const Head = () => {
  return (
    <SEO title="Location">
      <script type="application/ld+json">{JSON.stringify({})}</script>
    </SEO>
  );
};
